@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.title {
  text-align: center;
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 3.1vw;
}
.container {
  display: flex;
  margin-bottom: 50px;
  height: 68vw;
}
.screenshots {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.arrow_left {
  width: 3vw;
  height: auto;
  z-index: 100px;
  cursor: pointer;
}
.arrow_right {
  width: 3vw;
  height: auto;
  z-index: 10px;
  cursor: pointer;
  padding-left: 0.5vw;
}
