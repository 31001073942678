@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  margin: auto;
  background-color: $pureWhite;
  width: 93%;
  height: fit-content;
  padding-bottom: 10vw;
  border-radius: 15px;
  margin-bottom: 50px;
  margin-top: 10vw;
}
.text {
  color: $greyTextColor;
  width: 100%;
  font-family: "AndadaPro";
  margin: auto;
  margin-top: 35px;
}
.title {
  text-align: center;
  font-size: 7.6vw;
}
.paragraph {
  text-align: center;
  margin: auto;
  width: 80%;
  font-size: 4vw;
}
.image {
  display: block;
  margin: auto;
  width: 80%;
  height: auto;
  padding-top: 45px;
}
.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 54vw;
  height: 13vw;
  border-radius: 50px;
  font-size: 1.2vw;
  border: none;
  margin: auto;
  margin-top: 6vw;
  font-size: 20px;
  cursor: pointer;
}
.link{
  text-decoration: none;
}