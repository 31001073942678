@import "../../core/theme/colors.scss";

.container {
  background-color: $pureBlack;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 6vh;
  width: 8vw;
  border: 0.01px solid $buttonBorderGrey;
  border-radius: 10px;
}

a {
  color: #0060b6;
  text-decoration: none;
}

a:hover {
  color: #00a0c6;
  text-decoration: none;
  cursor: pointer;
}

.image {
  width: 1.4vw;
  height: auto;
  margin-right: -0.7vw;
}
.content {
  color: $pureWhite;
  display: flex;
  flex-direction: column;
}
.paragraph {
  margin-top: 0.4vw;
  margin-bottom: 0px;
  font-size: 11px;
}
.title {
  margin-top: 0;
  font-size: 0.8vw;
}
.inner {
  transition: transform 2s;
}
.photo {
  width: 20vw;
  width: min(425px, 20vw);
  display: flex;
}

.images {
  display: flex;
  flex-direction: column;
  margin-right: min(45px, 2vw);
}

.imageOurTeam {
  width: min(300px, 15.2vw);
  height: auto;
  margin: auto;
}

.images {
  display: flex;
  flex-direction: column;
  margin-left: 1.34vw;
}

.names {
  margin-top: 1.6vw;
}

.name {
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: clamp(0.5rem, 1.3vw, 2rem);
  text-align: center;
  margin: 0.1vw 0;
}
