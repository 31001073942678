@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  padding-top: .5vw;
  background-size: cover;
}
.content {
  display: flex;
}
.column1 {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.column2 {
  display: flex;
  flex-direction: column;
  width: 55%;
}
.image1 {
  width: 30vw;
  margin-bottom: 15vw;
  margin: auto;
}
.image2 {
  display: block;
  width: 25vw;
  margin: auto;
  margin-right: 4vw;
  margin-top: 9vw;
  margin-bottom: 4vw;
}
.image3 {
  width: 30vw;
  margin: auto;
}
.title {
  font-family: "AndadaPro";
  color: white;
  float: left;
  font-size: 4.3vw;
  font-weight: 700;
  align-items: center;
  margin: 80px 0vw 0 0vw;
}
.logoTitle {
  color: white;
  font-family: "BerkshireSwash";
  font-size: 9.9vw;
  font-weight: 700;
  align-items: center;
  margin-left: 0vw;
}
.logoContent{
  margin-right: 6.8vw;
  margin-top: 5vw;
}
.logoImage {
  float: right;
}
.lower {
  display: flex;
  justify-content: center;
  padding-bottom: 1vw;
  margin: auto;
  padding-top: 3vw;
}
.navi {
  font-family: "AndadaPro";
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 2vw;
  margin-right: 2vw;
}
.naviText {
  font-size: 1.7vw;
}
.icon {
  margin-right: 1vw;
  width: 3.7vw;
  height: auto;
}