@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  width: 100%;
  min-height: 64vw;
  background-size: cover;
}

.logoBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  width: 100%;
}

.logoImage {
  width: min(150px, 180px);
  height: auto;
}

.logoText {
  font-family: Berkshire Swash;
  color: $pureWhite;
  font-size: clamp(0.5rem, 3.2vw, 4rem);
  font-weight: 400;
  margin-left: 12px;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: $pureWhite;
}

.contentText {
  position: relative;
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.contentTitle {
  margin: 0;
  font-family: AndadaPro;
  font-size: clamp(0.5rem, 2.7vw, 3rem);
}

.contentParagraph {
  font-family: AndadaPro;
  margin: 3vh 0 5vw 0;
  font-size: clamp(0.5rem, 1.5vw, 2rem);
  width: min(800px, 32vw);
}

.buttons {
  display: flex;
}

.seeButton {
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 234px;
  height: 64px;
  border-radius: 50px;
  font-size: 20px;
  border: none;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 55vh;
  width: 40vw;
}

.imageWrapper {
  position: absolute;
  right: 4vw;
  bottom: 6vw;
  height: 55vh;
  width: 53vw;
}

.imageWrapper img {
  margin-top: -20vh;
  height: 100%;
  width: 100%;
}

.downloadButtons {
  display: flex;
  justify-content: space-around;
  width: clamp(300px, 20vw,500px);
  align-self: center;
}

.downloadApple,
.DownloadGoogle {
  margin-right: 5vw;
}

.storeButton {
  color: $pureWhite;
  background-color: $pureBlack;
}

.playButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
