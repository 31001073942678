@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  background-size: cover;
}
.uppper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  color: white;
}
.imageOne {
  width: 50%;
  margin-left: 4vw;
}
.title {
  font-family: "AndadaPro";
  font-size: 10.6vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
  margin: 6vw 0vw 10vw 34vw;
}
.imageTwo {
  width: 50%;
  margin-left: 41vw;
}
.middle {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 5vw;
}
.logoTitle {
  color: white;
  font-family: "BerkshireSwash";
  font-size: 15.3vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
}
.logoContent{
  margin-left: 10vw;
  margin-top: 2vw;
}
.logoImage {
  float: right;
  margin-right: 9vw;
}
.lower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vw;
  padding-bottom: 4vw;
}
.navi {
  font-family: "AndadaPro";
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 15vw;
  margin-right: 2vw;
  margin-top: 5vw;
}
.icon {
  margin-right: 1vw;
}