@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.title {
  text-align: center;
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 30px;
  margin-bottom: -20vw;
}
.container {
  display: flex;
  margin-top: 140px;
  margin-bottom: 50px;
}
.screenshots {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.screenshot {
  min-width: 74vw;
  height: auto;
  transform: translateX(0%);
  transition: 0.5s;
}
.arrow_left {
  width: 7.5vw;
  z-index: 100px;
  cursor: pointer;
  padding-right: 3vw;
  padding-left: 3vw;
}
.arrow_right {
  width: 7.5vw;
  height: auto;
  z-index: 100px;
  cursor: pointer;
  padding-left: 3vw;
  padding-right: 3vw;
}

@media only screen and (max-width: 445px) {
}