@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.textContent {
  background-repeat: no-repeat;
  background-size: 38vw;
  height: 100%;
}
.textContainer {
  display: flex;
}
.text {
  color: $greyTextColor;
  margin-top: 18vw;
  margin-bottom: -150px;
  width: 47vw;
}
.title {
  font-family: "AndadaPro";
  font-size: 4vw;
  font-weight: 700;
  align-items: center;
  margin: 2vw 0 0 10vw;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 3vw;
  margin: 3vw 0 0 10vw;
}
.uppper {
  display: flex;
  flex-direction: column;
}
.firstImage {
  margin-top: 12vw;
  width: 35vw;
}
.secondImage {
  display: block;
  margin: auto;
  margin-top: 40vw;
  width: 20vw;
}
.thirdImage {
  margin-top: 15vw;
  width: 30vw;
}
.container {
   background-size: cover;
   padding-top: -5vw;
}
.uppper {
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
}
.middle {
  display: flex;
  justify-content: space-between;
  margin-left: 10vw;
  height: 100vw;
}
.logoContent {
  padding-top: 80vw;
  margin-right: 6vw;
}
.logoTitle {
  color: white;
  font-family: "BerkshireSwash";
  font-size: 7.8vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
}
.logoImage {
  float: right;
  width: 22vw;
}
.lower {
  display: flex;
  justify-content: center;
  padding-bottom: 1vw;
  margin: auto;
}
.navi {
  font-family: "AndadaPro";
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 2vw;
  margin-right: 2vw;
}
.naviText {
  font-size: 1.7vw;
}
.icon {
  margin-right: 1vw;
  width: 3.7vw;
  height: auto;
}
.iconText {
  font-size: 1.9vw;
}
@media only screen and (max-width: 280px) {
  .middle {
  margin-top: 26.5vw;
}
}