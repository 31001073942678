@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  display: flex;
  margin: 10vw auto auto auto;
  background-color: $pureWhite;
  width: 95%;
  height: 44vw;
  border-radius: 15px;
  margin-bottom: 50px;
}
.text {
  color: $greyTextColor;
  width: 50%;
  font-family: "AndadaPro";
  margin: auto;
  margin-left: 0px;
}
.title {
  text-align: center;
  font-size: 3vw;
  margin-top: 0;
}
.paragraph {
  text-align: center;
  margin: auto;
  width: 74%;
  font-size: 1.6vw;
}
.imageContainer {
  margin: auto;
  width: 45%;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 26.6vw;
  height: 6.4vw;
  border-radius: 50px;
  font-size: 1.2vw;
  border: none;
  margin: auto;
  margin-top: 2vw;
  font-size: 2vw;
  cursor: pointer;
}
.link{
  text-decoration: none;
}
