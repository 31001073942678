@import '../../core/theme/colors.scss';

.container {
  background-color: $pureBlack;
  display: flex;
  justify-content: space-around;
  height: 6.5vw;
  width: 20vw;
  border: 0.01px solid $buttonBorderGrey;
  border-radius: 10px;
}

a {
  color: #0060B6;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

.image {
  width: 3.6vw;
  height: auto;
  margin-right: -15px;
}
.content {
  color: $pureWhite;
  display: flex;
  flex-direction: column;
}
.paragraph {
  font-size: 11px;
  margin: auto;
}
.title {
  margin-top: 0;
  font-size: 2vw;
}
.inner {
  transition: transform 0.3s, width 0.3s;
  transition-timing-function: linear;
  margin: auto;
}
.photo {
  width: 28.5vw;
  height: auto;
  transition: transform 0.3s, width 0.3s;
  transition-timing-function: linear;
}
.innerTeam {
  transition: transform 1s;
}
.name {
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 1.3vw;
  text-align: center;
  line-height: 0;
}
.imageOurTeam {
  width: 25vw;
  height: auto;
  margin: auto;
}
.images {
  display: flex;
  flex-direction: column;
  margin: 0 1.25vw;
}
.middlePhoto {
  width: 35.5vw;
  height: auto;
  transition: transform 0.3s, width 0.3s;
  transition-timing-function: linear;
}
.imageContainer {
  margin: auto;
}
@media only screen and (max-width: 670px) {
  .container {
  height: 7vw;
  width: 22vw;
}
}