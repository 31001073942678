@import "../../core/theme/colors.scss";

.container {
  background-color: $pureBlack;
  display: flex;
  justify-content: space-around;
  height: 10vw;
  width: 29.2vw;
  border: 0.01px solid $buttonBorderGrey;
  border-radius: 10px;
}

a {
  color: #0060B6;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

.image {
  width: 6vw;
  height: auto;
  margin-right: -15px;
}
.content {
  color: $pureWhite;
  display: flex;
  flex-direction: column;
}
.paragraph {
  margin-top: 0.4vw;
  margin-bottom: 0px;
  font-size: 1.9vw;
}
.title {
  margin-top: 0;
  font-size: 3.5vw;
}
.inner {
  transition: transform 1s;
  transition-timing-function: linear;
}
.photo {
  width: 76.2vw;
  height: auto;
}
@media only screen and (max-width: 447px) {
  .photo {
    width: 73.8vw;
  }
}
