@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  align-items: center;
  border: 1px solid $greyTextColor;
  border-radius: 50px;
  width: 80%;
  width: min(1700px, 80%);
  margin: auto;
  margin-top: 5vw;
  margin-bottom: 5vw;
}
.text {
  text-align: center;
  margin-top: 3vw;
  color: $greyTextColor;
}
.title {
  font-family: "AndadaPro";
  font-size: clamp(0.5rem, 3.2vw, 4rem);
}
.paragraph {
  font-family: "AndadaPro";
  font-size: clamp(0.5rem, 1.5vw, 2rem);
  margin: auto;
  margin-top: 2vw;
  width: 80%;
}
.downloadButtons {
  padding-top: 5vw;
  padding-bottom: 2vw;
  display: flex;
  justify-content: space-around;
  width: 25%;
  align-self: center;
  margin: auto;
}
