@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  display: flex;
  margin: -10vw auto auto auto;
  background-color: $pureWhite;
  width: min(1700px, 75%);
  height: 29vw;
  border-radius: 15px;
  margin-bottom: 100px;
}
.text {
  width: 38%;
  font-family: "AndadaPro";
  margin: auto;
  margin-left: 0px;
  color: $greyTextColor;
}
.title {
  color: $greyTextColor;
  text-align: center;
  font-size: 2vw;
  margin-top: 0;
  font-size: clamp(0.5rem, 2.7vw, 3rem);
}
.paragraph {
  text-align: center;
  margin: auto;
  width: 82%;
  font-size: clamp(0.5rem, 1vw, 1.6rem);
}

.imageContainer {
  margin: auto;
  width: 50%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: min(15vw, 400px);
  height: min(3.3vw, 90px);
  border-radius: 50px;
  border: none;
  margin: auto;
  margin-top: 2vw;
  font-size: clamp(0.5rem, 1vw, 1.6rem);
  cursor: pointer;
}
.link {
  text-decoration: none;
}
