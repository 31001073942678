@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.title {
  text-align: center;
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 3.1vw;
  margin-top: 6vw;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 1.5vw;
  width: 80%;
  margin: auto;
  margin-bottom: 4vw;
  margin-top: 2vw;
  text-align: center;
  color: $greyTextColor;
}
.container {
  display: flex;
  margin-top: 60px;
  margin-bottom: 20px;
  justify-content: center;
}
.screenshots {
  display: grid;
  flex-wrap: wrap;
  width: 83vw;
  overflow: hidden;
  grid-auto-flow: column dense;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 1fr;
}
.arrow_left {
  width: 3vw;
  height: auto;
  z-index: 100px;
  cursor: pointer;
}
.arrow_right {
  padding-left: 0.1vw;
  width: 3vw;
  height: auto;
  z-index: 10px;
  cursor: pointer;
}
.dotsContainer {
  display: flex;
  justify-content: center;
}
.dots {
  display: flex;
}
.dot {
  display: block;
  background-color: #3b4153;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  transition: width 0.5s;
}
.line {
  display: block;
  background-color: #3b4153;
  width: 28px;
  height: 14px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  transition: width 0.5s;
}
@media only screen and (max-width: 800px) {
  .dot {
    width: 10px;
    height: 10px;
  }
  .line {
    width: 20px;
    height: 10px;
  }
}