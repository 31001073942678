@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  margin: auto;
}
.text {
  font-family: "AndadaPro";
  color: $greyTextColor;
  margin: auto;
  margin-top: 7vw;
}
.title {
  font-size: 7vw;
  text-align: center;
  width: 80%;
  margin: auto;
}
.paragraph {
  margin: auto;
  width: 78%;
  font-size: 3.8vw;
  text-align: center;
  margin-top: 5vw;
  margin-bottom: 4vw;
}
.images {
  padding-top: 3vw;
  display: flex;
  width: 89%;
  margin: auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.image {
  width: 40vw;
  height: auto;
  margin: auto;
}
.name {
  font-family: "AndadaPro";
  color: $greyTextColor;
  font-size: 3vw;
  text-align: center;
}
.text2 {
  margin: auto;
  margin-bottom: 40px;
  width: 94%;
}
.title2 {
  font-family: "AndadaPro";
  color: $greyTextColor;
  text-align: center;
  font-size: 8vw;
  margin-top: 13vw;
}
.paragraph2 {
  font-family: "AndadaPro";
  color: $greyTextColor;
  text-align: center;
  width: 100%;
  margin: auto;
  margin-top: 2vw;
  font-size: 16px;
}
.seeButton {
  display: block;
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 180px;
  height: 48px;
  border-radius: 50px;
  font-size: 4vw;
  border: none;
  margin: auto;
  margin-top: -3vw;
  cursor: pointer;
}
