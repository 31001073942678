@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.container {
  margin-top: -10px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.logoBar {
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-left: 17px;
}
.logoText {
  font-family: Berkshire Swash;
  color: $pureWhite;
  font-size: 24px;
  font-weight: 400;
  margin-left: 12px;
}
.logoImage {
  width: 15vw;
}
.content {
  display: flex;
  flex-direction: column;
  color: $pureWhite;
}
.contentText {
  margin-top: 9vw;
  margin-left: 6vw;
}
.contentTitle {
  font-family: AndadaPro;
  font-size: 24px;
  margin: 0;
}
.contentParagraph {
  font-family: AndadaPro;
  align-self: center;
  text-align: center;
  margin-top: 12vw;
  font-size: 3.7vw;
  width: 80%;
  color: $greyTextColor;
}
.contentImage {
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.imageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons {
  display: flex;
  margin-top: 5vw;
}
.seeButton {
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 45vw;
  height: 11vw;
  border-radius: 50px;
  font-size: 4.26vw;
  border: none;
  cursor: pointer;
}
.storeButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
.downloadButtons {
  margin-top: 7vw;
  display: flex;
  justify-content: space-around;
  width: 70%;
  align-self: center;
}
.playButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
