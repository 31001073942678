@import "../../../core/theme/colors.scss";
@import "../../../core/theme/fonts.scss";

.textContent {
  background-repeat: no-repeat;
}
.textContainer{
  display: flex;
}
.text {
  margin-top: 220px;
  margin-bottom: -150px;
  width: 70vw;
  color: $greyTextColor;
}
.title {
  font-family: "AndadaPro";
  font-size: 2.8vw;
  font-weight: 700;
  align-items: center;
  margin: 80px 0 0 10vw;
}
.paragraph {
  font-family: "AndadaPro";
  font-size: 1.9vw;
  margin: 80px 0 0 10vw;
}
.uppper{
  display: flex;
  flex-direction: column;
}
.firstImage{
  margin-top: 1vw;
  width: 35vw;
  float: right;
  margin-right: 3vw;
}
.secondImage{
  display: block;
  margin: auto;
  margin-top: 57vw;
  width: 27vw;
}
.container {
  padding-top: 20vw;
  background-size: cover;
}
.uppper {
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
}
.middle {
  display: flex;
  justify-content: space-between;
  margin-left: 10vw;
}
.logoContent{
  margin-right: 5vw;
  margin-top: 2vw;
}
.logoTitle {
  color: white;
  font-family: "BerkshireSwash";
  font-size: 4.3vw;
  font-weight: 700;
  align-items: center;
  text-align: center;
  margin-left: 21vw;
}
.thirdImage{
  width: 25vw;
}
.logoImage {
  float: right;
  width: 10vw;
}
.lower {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
  padding-bottom: 1vw;
}
.navi {
  font-family: "AndadaPro";
  display: flex;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 2vw;
  margin-right: 2vw;
}
.icon {
  margin-right: 1vw;
}