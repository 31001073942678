@import '../../../core/theme/colors.scss';
@import "../../../core/theme/fonts.scss";

.container {
  margin-top: -10px;
  width: 100%;
  height: 80vw;
  background-size: cover;
}
.logoBar {
  display: flex;
  align-items: center;
  padding-top: 60px;
  margin-left: 63px;
}
.logoImage {
  width: 100px;
  height: auto;
}
.logoText {
  font-family: Berkshire Swash;
  color: $pureWhite;
  font-size: 35px;
  font-weight: 400;
  margin-left: 12px;
}
.content {
  display: flex;
  color: $pureWhite;
}
.contentText {
  margin-top: 5vw;
  margin-left: 6vw;
}
.contentTitle {
  font-family: AndadaPro;
  font-size: 3vw;
}
.contentParagraph {
  font-family: AndadaPro;
  align-self: center;
  font-size: 2.2vw;
  width: 100%;
}
.contentImage {
  position: relative;
  width: 100%;
  height: auto;
  margin-right: 20vw;
}
.imageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  margin-top: 15vw;
}
.seeButton {
  font-family: AndadaPro;
  background-color: $seeButton;
  color: $pureWhite;
  width: 23.6vw;
  height: 6.4vw;
  border-radius: 50px;
  font-size: 2vw;
  border: none;
  cursor: pointer;
}
.storeButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
.downloadButtons {
  margin-top: 7vw;
  display: flex;
  justify-content: space-around;
  width: 52vw;
  align-self: center;
}
.playButton {
  color: $pureWhite;
  background-color: $pureBlack;
}
